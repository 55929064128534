import coming from '../headers_imgs/coming.gif';
import { Container,Row, Col } from "react-bootstrap";
function Bujho()
{
    return(
        <div>
          <Container className="content-box">
            <Row>
                <Col>
                <h2>Welcome to 'Bujho - Quiz.'</h2>
                <img src={coming}/>
  <p className="content_class">The word 'Quiz' originated from the Latin language, where its meaning is "Who are you?" In general, the term 'Quizzical' is used to refer to someone who engages in quizzes. A quiz is a collection of questions and answers used to assess people's knowledge. Solving puzzles has been an integral part of the local culture in all countries. Quizzes also provide entertainment. Programs like "Who Wants to Be a Millionaire?" have established the popularity of quizzes. It not only engages the questioner and the respondent but also entertains and benefits those who listen to and read it.</p>

  <p className="content_class">Here, there are three types of activities available:</p>
  <ul>
    <li>Competitive</li>
    <li>Practice/Exercise</li>
    <li>Question of the day</li>
  </ul>

  <h4>Registration:</h4>
 
  <p className="content_class">Users must register first. Choose one of the two options before registration: "Medical Professional" or "Others." Based on this selection, you will have access to a daily question from the "Question of the Day" section, as well as questionnaires based on various categories and subjects.</p>

  <p className="content_class">In both the "Competitive" and "Practice" sections, you will see a list of multiple questionnaires. The list will indicate the subject of the questionnaire, the difficulty level, the maximum score for the questions, and the creation date. The competitive section will also display the deadline for each questionnaire.</p>

  <p className="content_class">After registration, you can log in using the user ID (email/mobile) and password you created.</p>

  <h4>A. Competitive Quiz:</h4>
  <p className="content_class">The questionnaires available in the competitive section will only be accessible for a specific duration. After that period, you can find those questionnaires in the practice section. (The start and end dates are displayed for each competition.)</p>

  <p className="content_class">Each competition has a specific time limit. If you don't submit your answers within the allotted time, the paper will be automatically submitted.</p>

  <p className="content_class">If you wish to submit before the time limit, you can do so.</p>

  <p className="content_class">The score for each correct answer will be based on the difficulty level of the question, ranging from 1 to 4, where 1 is the easiest and 4 is the most difficult. Negative marks will be deducted for incorrect answers. Examples: If the score is '1,' the negative mark will be ¼ or 0.25. If the score is '2,' the negative mark will be ½ or 0.5. If the score is '3,' the negative mark will be ⅓ or 0.75. If the score is '4,' the negative mark will be 1 or 1.0.</p>

  <p className="content_class">After submission, you can view your correct and incorrect answers, score, explanation, and percentage. The percentile rank will be visible only after the competition ends.</p>

  <p className="content_class">Within the time limit, you can pause (stop) the competition if you want. You can log in later and resume it, and your answers and time calculation will be saved.</p>

  <p className="content_class">If you pause a competition, remember that you won't be able to resume it after the competition's end date. Your entry will be considered invalid.</p>

  <p className="content_class">Note: In the competitive section, time is also used for evaluation. This means that the competitor who submits the most correct answers in a shorter time will receive more points. (Pausing the competition will not count towards the total time.)</p>

  <h4>B. Practice:</h4>
  <p className="content_class">There is no deadline for the practice section. You can solve it anytime.</p>

  <p className="content_class">As soon as you choose an option in the practice section, you will immediately receive the answer. Therefore, you cannot change your choice once selected.</p>

  <p className="content_class">There are no negative marks in the practice section.</p>

  <p className="content_class">There is no time limit in the practice section.</p>

  <p className="content_class">If you want, you can pause the competition, and later, when you log in again, you can resume it, and your answers will be saved.</p>

  <h4>C. Today's Question:</h4>
  <p className="content_class">Under this section, you will receive one question daily.</p>

  <p className="content_class">Based on the option chosen during registration ("Medical Professional" or "Others"), you will see today's question.</p>

  <p className="content_class">After selecting an option and submitting it, you will immediately see the result.</p>

  <p className="content_class">Today's question will be presented through a "Pop-up" window. It can also be viewed by clicking on the "Archive" (Old Collection) in the menu given on the right-hand side.</p>

  <p className="content_class">Vocabulary in the Today's Question section:</p>
  <ul>
    <li>a. Longest Streak: The longest consecutive series of correct answers.</li>
    <li>b. Active Longest Streak: The longest and currently active (not broken) consecutive series of correct answers.</li>
    <li>c. Total Score: The overall score achieved.</li>
    <li>d. Percentage: The percentage score obtained.</li>
  </ul>
                </Col>
            </Row>
          </Container>
        </div>
    )
}
export default Bujho;