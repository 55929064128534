import { Container, Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import nrc_img from '../headers_imgs/nrc_course.webp';
import { Link } from "react-router-dom";
import who_apply from '../headers_imgs/who_can_apply.webp';
import how_apply from '../headers_imgs/how_to_apply.webp';
import study from '../headers_imgs/A01I1007.webp';
import {Image} from "react-bootstrap";
function NRC ()
{
    return (
        <div>
                <Helmet>
        <title>Neurology Refresher Course | Pauranik Academy of Medical Education</title>
    <meta name="keywords" content="Pauranik Academy of Medical Education, PAME, medical education, neurology refresher course, NRC, healthcare professionals, continuing medical education, CME, evidence-based information, practical skills, communication skills, Hindi medium education, patient care, online reinforcements, neurological disorders, healthcare practice"></meta>
<meta name="description" content="Pauranik Academy of Medical Education (PAME) is dedicated to providing comprehensive medical education to healthcare professionals. Through the Neurology Refresher Course (NRC), PAME empowers participants with evidence-based knowledge, practical skills, and effective communication techniques. With a focus on Hindi medium education, the NRC aims to enhance competence and confidence in the diagnosis and management of neurological disorders. Join PAME and embark on a journey of continual learning and excellence in healthcare practice."></meta>

      </Helmet>
      <img src = {nrc_img} className="img-fluid"/>
          <Container className="content-box">
            <Row>
                <Col>
                <h2>Neurology Refresher Course</h2>
            <p className="content_class highlight">
              <strong>Course Fee: ₹5,000 | Seats: 25  | Duration: 2 Days</strong>
            </p>
            <div>
             <div style={{fontSize:'20px', textAlign:'justify'}}>
             <img className="d-block d-sm-none img-thumbnail" src={study} />
             <img className="d-none d-sm-block" height={250} src={study} style={{float:'left', margin:'5px'}}/>
         
            Pauranik Academy of Medical Education (PAME) is a dedicated institution with a mission to provide comprehensive medical education to medical students, doctors, healthcare workers, patients, caregivers, and volunteers in the health sector. At PAME, our primary aim is to equip healthcare professionals with the knowledge and skills necessary to excel in their respective fields. One of our flagship activities is the Neurology Refresher Course (NRC), designed to offer continuing medical education to family physicians, general practitioners, and internists.

            <h4>Enhancing Competence and Confidence:</h4>

            The NRC at PAME is a two-day, six-hour monthly program that focuses on imparting evidence-based and practically useful information regarding common neurological conditions. We understand the importance of a well-rounded medical education that goes beyond theoretical knowledge. Therefore, we emphasize the art and science of history taking, clinical examination, laboratory investigations, differential diagnosis, therapeutic planning, referral strategies, prognostication, and patient counseling, all delivered in the participants' own language.
            </div>
            </div>
            <h4>
            Innovative and Interactive Teaching Methods:
            </h4>
            <p className="content_class">
            What sets the NRC apart from other Continuing Medical Education (CME) courses is its innovative and interactive nature. We believe in engaging our participants through a range of pedagogical teaching methods. Our sessions include lectures, workshops, quizzes, case discussions, assignments, audio and video recordings of patients, and comprehensive reading materials to take home. This multidimensional approach ensures that participants gain a deep understanding of the subject matter and are equipped with practical skills.
            </p>
            <h4>
            Communication Skills and Hindi Medium Education:
            </h4>
            <p className="content_class">
            Effective communication is paramount in the healthcare field. At PAME, we understand the significance of strong communication skills for healthcare professionals. Hence, we provide specialized training to our participants in effective communication techniques. Additionally, we emphasize the importance of becoming educators who can effectively disseminate medical knowledge to patients, caregivers, and the general public. PAME is committed to providing education in Hindi medium, allowing participants to communicate confidently and clearly with a larger population.
            </p>
            <h4 style={{color:'red'}}>
            Who can Apply :
            </h4>
            <div>
            <Image height={150} className="d-none d-sm-block" src={who_apply} style={{float:'right'}}/>
            <Image  className="d-block d-sm-none img-thumbnail" src={who_apply} />
            <div style={{fontSize:'20px'}}>   
            The Neurology Refresher Course (NRC) at Pauranik Academy of Medical Education (PAME) is open to doctors from diverse backgrounds who wish to enhance their medical acumen in the field of neurological disorders. We welcome applications from doctors who are committed to serving their patients in an ethical and improved manner. Whether you hold an MBBS degree, a diploma, or an MD, you are eligible to apply for the NRC.
            
            
The NRC is specifically designed to cater to the needs and interests of general practitioners, family physicians, pediatricians, and internists. We understand that neurological disorders can present in various medical contexts, and our course content is tailored to address these diverse perspectives. The comprehensive curriculum of the NRC will greatly benefit doctors who aim to broaden their knowledge and skills in the field of neurology. </div>
            </div>
<p className="content_class">
Furthermore, Ayush practitioners, who hold degrees in Ayurveda, Yoga & Naturopathy, Unani, Siddha, or Homeopathy, are also encouraged to apply for the NRC. However, the selection of Ayush practitioners will depend on their educational background and professional experience.
            </p>
            <h4 style={{color:'red'}}>How to Apply:</h4>
   <div style={{fontSize:'20px', border:'1px solid black', padding:'15px',textAlign:'justify'}}>         
      <img src={how_apply} className="d-none d-sm-block" style={{float:'right'}}/>
      <img src={how_apply} className="d-block d-sm-none img-thumbnail" />
            <ol>
  <li>
    <p>Click on the following link: <Link to={'/registration'} onClick={() => window.scrollTo(0, 0)}>Apply Now</Link>.</p>
  </li>
  <li>
    <p>Provide us with a brief overview of your professional background, including your qualifications and experience. Additionally, share your interests and reasons for wanting to participate in the NRC. This information will help us understand your profile and tailor the course content to suit your needs.</p>
  </li>
  <li>
    <p>Once you have successfully registered, you have the option to pay the course fee online or offline.</p>
    <ul>
      <li>
        <p>Online Payment: After completing the registration process, you will find a payment button. Click on it to proceed with the online payment. Follow the provided instructions to make the payment securely online.</p>
      </li>
      <li>
        <p>Offline Payment: If you prefer to pay the fee offline, you can visit our center after registration. Our staff will assist you in completing the payment process in person.</p>
      </li>
    </ul>
  </li>
  <li>
    <p>Please note that your enrollment will be confirmed only after the successful payment, regardless of whether you choose to pay online or offline. Ensure that you complete the payment process to secure your seat in the NRC.</p>
  </li>
  <li>
    <p>Seats for the NRC are limited to 25 participants and will be allocated on a first-come, first-served basis. We recommend submitting your application and making the payment at the earliest to secure your spot in the course.</p>
  </li>
</ol></div>
                </Col>
            </Row>
          </Container>
        </div>
    )
}
export default NRC