import React, { Component } from 'react';
import { CheckoutProvider, Checkout } from 'paytm-blink-checkout-react';

import InjectedCheckout from './InjectedCheckout';

const USE_EXISTING_CHECKOUT_INSTANCE = 'Use existing checkout instance : ';
const CONFIG = {
  style: {
      bodyBackgroundColor: "#fafafb",
      bodyColor: "",
      themeBackgroundColor: "#dfa231",
      themeColor: "#ffffff",
      headerBackgroundColor: "#284055",
      headerColor: "#ffffff",
      errorColor: "",
      successColor: "",
      card: {
          padding: "",
          backgroundColor: ""
      }
  },
  jsFile: "",
  data: {
      orderId: "",
      amount: "1",
      token: "",
      tokenType: "TXN_TOKEN",
      userDetail: {
          mobileNumber: "",
          name: ""
      }
  },
  merchant: {
      mid: "KcdeTm69742642069367",
      logo: "",
      redirect: true, 
    
  },
  mapClientMessage: {},
  labels: {},
  payMode: {
      labels: {},
      filter: {
          exclude: []
      },
      order: [
          "BALANCE",
          "UPI",
          "NB",
          "CARD",
          "LOGIN"
      ]
  },
  flow: "DEFAULT",
  handler: {  //seee here how i am handling data , now see on app
    "notifyMerchant": function (eventName, data) {
      console.log("notifyMerchant handler function called");
      console.log("eventName => ", eventName);
      console.log("data => ", data); /// 
    }
  }
 
};


class Payment extends Component {
  textAreaRef = React.createRef();

  constructor(props) {
    super(props);

    const { order_id, token } = props;

    this.state = {
      config: this.appendHandler({
        ...CONFIG,
        data: {
          ...CONFIG.data,
          orderId: order_id,
          token: token
        }
      }),
      showCheckout: false,
      openInPopup: true,
      checkoutJsInstance: null
    };
  }

  appendHandler(config) {
    const newConfig = { ...config };

    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler
    }

    return newConfig;
  }

  notifyMerchantHandler = (eventType, data) => {
    console.log('MERCHANT NOTIFY LOG', eventType, data);
  }

  renderUpdateConfig = () => {
    this.setState({
      config: this.getUpdatedConfig()
    });
  }

  getUpdatedConfig() {
    const config = this.parse(this.textAreaRef.current.value);

    return this.appendHandler(config);
  }

  parse(value) {
    try {
      return JSON.parse(value)
    }
    catch (err) {
      console.error("Invalid config JSON");
      return {};
    }
  }





  loadCheckoutScript = () => {
    const url = 'https://pgp-hotfix.paytm.in/merchantpgpui/checkoutjs/merchants/';
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = url.concat(CONFIG.merchant.mid);
    scriptElement.type = 'application/javascript';
    scriptElement.onload = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();

      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          this.setState({
            config: this.getUpdatedConfig(),
            checkoutJsInstance
          });
        });
      }
      else {
        console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'onload not available!');
      }
    };
    scriptElement.onerror = error => {
      console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'script load fail!');
    }
    document.body.appendChild(scriptElement);
  }

  getCheckoutJsObj() {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    }
    else {
      console.error(USE_EXISTING_CHECKOUT_INSTANCE + 'Checkout instance not found!');
    }

    return null;
  }

  render() {
    const { showCheckout, openInPopup, config } = this.state;
    const textAreaVal = JSON.stringify(config, null, 4);

    return (
      <div>
 

        <div><b>CHECKOUT VISIBILITY :</b> {showCheckout.toString()}</div>
        <CheckoutProvider config={this.state.config}
          checkoutJsInstance={this.state.checkoutJsInstance}
          openInPopup={true} 
          env="STAGE">
          <InjectedCheckout />
          <Checkout />
        </CheckoutProvider>
      </div>
    );
  }
}

export default Payment;