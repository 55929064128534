import { Container, Row, Col } from "react-bootstrap"
function  Privacy_hi()
{
     return (
        
<Container className="content-box">
    <Row>
        <Col>
            <h2>गोपनीयता नीति</h2>
            <span className="last-updated">अंतिम अपडेट: 19 मई, 2023</span>
            <p className="content_class">
                पौराणिक मेडिकल एजुकेशन प्राइवेट लिमिटेड ("हम", "हमारा" या "आपका") वेबसाइट www.pauranikeducation.in (वेबसाइट) का संचालन करता है। हम आपकी गोपनीयता के महत्व को समझते हैं और आपकी व्यक्तिगत जानकारी की सुरक्षा करने के लिए प्रतिबद्ध हैं। यह गोपनीयता नीति बताती है कि हम आपकी जानकारी कैसे एकत्र करते हैं, उपयोग करते हैं, प्रकट करते हैं और सुरक्षित रखते हैं जब आप हमारी वेबसाइट पर जाते हैं।
                <br/><br/>
                हमारी वेबसाइट तक पहुँच करने या उपयोग करने पर, आप इस गोपनीयता नीति की शर्तों से सहमत होते हैं। यदि आप इस नीति में वर्णित अमलों से सहमत नहीं हैं, तो कृपया वेबसाइट का उपयोग न करें।
            </p>
            <h4>हमारी जानकारी एकत्र करने की विधियाँ</h4>
            <h5>1.1. व्यक्तिगत जानकारी:</h5>
            <p className="content_class">
                हम आपकी व्यक्तिगत जानकारी एकत्र कर सकते हैं, जैसे कि आपका नाम, ईमेल पता, पोस्टल पता, फोन नंबर और आपकी वेबसाइट के साथ अंतर्क्रिया करते समय आप हमें स्वैच्छिक रूप से प्रदान करते हैं। यह जानकारी हमें आपके प्रश्नों का उत्तर देने, अनुरोधित सेवाएं प्रदान करने और हमारी वेबसाइट पर अपने अनुभव को सुधारने के लिए आवश्यक होती है।
</p>
<h5>1.2. गैर-व्यक्तिगत जानकारी:</h5>
<p className="content_class">
हम आपके द्वारा हमारी वेबसाइट में नेविगेट करते समय स्वचालित रूप से गैर-व्यक्तिगत जानकारी भी एकत्र कर सकते हैं। इसमें आपका IP पता, ब्राउज़र प्रकार, ऑपरेटिंग सिस्टम, डिवाइस जानकारी और अन्य तकनीकी जानकारी शामिल हो सकती है। हम इस जानकारी का उपयोग ट्रेंड का विश्लेषण करने, वेबसाइट का प्रशासन करने और हमारे उपयोगकर्ता आधार की जनसांख्यिकीय जानकारी एकत्र करने के लिए करते हैं।
</p>
<h4>आपकी जानकारी का हम उपयोग कैसे करते हैं</h4>
<h5>2.1. व्यक्तिगत जानकारी:</h5>
<p className="content_class">
हम आपकी व्यक्तिगत जानकारी का उपयोग करके निम्नलिखित कार्यों के लिए कर सकते हैं:
</p>
<ul>
<li>आपके प्रश्नों का उत्तर देना, ग्राहक सहायता प्रदान करना और आपकी अनुरोधों को पूरा करना।</li>
<li>नीतियों पर अपडेट, सेवाओं में परिवर्तन जैसे प्रशासनिक सूचनाएं भेजना।</li>
<li>हमारी वेबसाइट पर आपके अनुभव को व्यक्तिगत बनाना और सुधारना।</li>
<li>आपकी सहमति के साथ मार्केटिंग और प्रचार संचार भेजना।</li>
</ul>
<h5>2.2. गैर-व्यक्तिगत जानकारी:</h5>
<p className="content_class">
हम गैर-व्यक्तिगत जानकारी को निम्नलिखित उद्देश्यों के लिए उपयोग कर सकते हैं:
</p>
<ul>
<li>वेबसाइट पर ट्रेंड, उपयोग और गतिविधियों का निगरानी और विश्लेषण करना।</li>
<li>वेबसाइट की कार्यक्षमता और सामग्री को सुधारना।</li>
<li>हमारी सेवाओं को बेहतर बनाना और नए सुविधाओं का विकास करना।</li>
</ul>
<h4>आपकी जानकारी का हम कैसे प्रकट करते हैं</h4>
<h5>3.1. सेवा प्रदाता:</h5>
<p className="content_class">
हम तीसरे पक्ष सेवा प्रदाता द्वारा अपने लिए कार्यों और सेवाओं का प्रदान करने के लिए थिर्ड-पार्टी सेवा प्रदाताओं का उपयोग कर सकते हैं। इन सेवा प्रदाताओं को आपकी व्यक्तिगत जानकारी का पहुंच हो सकता है, लेकिन केवल उन्हें अनुरोधित सेवाओं के प्रदान करने के लिए उपयोग करने की अधिकृतता होती है।
</p>
<h5>3.2. कानूनी आवश्यकताएं:</h5>
<p className="content_class">
हम आपकी जानकारी को कानून द्वारा आवश्यक माने जाने पर या यथार्थ में विश्वास करने के लिए खोल सकते हैं, जैसे कि कानूनी दायित्वों का पालन करने, हमारे अधिकारों या संपत्ति की सुरक्षा रखने, धोखाधड़ी रोकने या उपयोगकर्ताओं की व्यक्तिगत सुरक्षा की सुरक्षा करने के लिए।
</p>
<h5>3.3. व्यापारिक संचालन:</h5>
<p className="content_class">
हमारी संपत्ति के सभी या किसी हिस्से की एक विलय, अधिग्रहण या बिक्री के मामले में, आपकी जानकारी सौदे के हिस्से के रूप में स्थानांतरित की जा सकती है। हम आपको ईमेल और/या हमारी वेबसाइट पर प्रमुख सूचनाद्वारा सूचित करेंगे कि स्वामित्व या आपकी व्यक्तिगत जानकारी के उपयोग में कोई परिवर्तन हुआ है।</p>

<h4>कुकीज़ और समान प्रौद्योगिकियों का उपयोग</h4>
<p className="content_class">
    हम आपकी वेबसाइट पर अपने अनुभव को बढ़ाने के लिए कुकीज़ और इसके समान ट्रैकिंग प्रौद्योगिकियों का उपयोग कर सकते हैं। कुकीज़ एक छोटी फ़ाइल होती है जो जब आप वेबसाइट पर जाते हैं तो आपके डिवाइस पर स्थापित की जाती है, और इससे हमें आपके ब्राउज़र को पहचानने और कुछ जानकारी याद रखने की क्षमता प्राप्त होती है। आप अपने ब्राउज़र को सभी कुकीज़ को अस्वीकार करने या कुकी भेजी जा रही है की जानकारी की सूचना देने के लिए निर्देशित कर सकते हैं। हालांकि, यदि आप कुकीज़ को स्वीकार नहीं करते हैं, तो आपको हमारी वेबसाइट के कुछ हिस्सों का उपयोग नहीं करने में सक्षम नहीं हो सकता।
</p>
<h4>डेटा सुरक्षा</h4>
<p className="content_class">
    हम अनधिकृत पहुंच, संशोधन, उजागर करने या नषवाई करने से आपकी व्यक्तिगत जानकारी की सुरक्षा के लिए उचित सुरक्षा उपाय लागू करते हैं। हालांकि, कृपया ध्यान दें कि इंटरनेट या इलेक्ट्रॉनिक संचार के माध्यम से जानकारी के अवैध पहुंच, संशोधन, उजागरण या नष्ट होने का कोई पूर्ण सुरक्षा नहीं है। हम आपकी जानकारी की पूर्ण सुरक्षा की गारंटी नहीं दे सकते हैं। </p>

<h4>तृतीय-पक्ष वेबसाइटों के लिंक</h4>
<p className="content_class">
    हमारी वेबसाइट पर तृतीय-पक्ष वेबसाइटों के लिंक हो सकते हैं। हम इन तृतीय-पक्ष वेबसाइटों की सामग्री या गोपनीयता नीतियों का नियंत्रण नहीं करते हैं और न इनकी प्रशंसा करते हैं। यह गोपनीयता नीति केवल हमारी वेबसाइट के माध्यम से एकत्र की गई जानकारी पर लागू होती है। हम आपको सलाह देते हैं कि आप जिन तृतीय-पक्ष वेबसाइटों को विजिट करते हैं, उनकी गोपनीयता नीतियों का समीक्षा करें ताकि आप इनकी जानकारी के एकत्र, उपयोग और उजागरण की प्रक्रिया को समझ सकें।</p><h4>बच्चों की गोपनीयता</h4>
<p className="content_class">
    हमारी वेबसाइट का उपयोग 16 वर्ष से कम आयु के व्यक्तियों के लिए नहीं है। हम जानेंते नहीं हैं कि हमारे पास अवचेतनता से किसी की व्यक्तिगत जानकारी का एकत्रण किया गया है जो 16 वर्ष से कम आयु के किसी बच्चे का हो सकता है। अगर हमें यह ज्ञात हो जाता है कि हमने अवचेतनता से किसी अवयस्क की व्यक्तिगत जानकारी का एकत्रण किया है, तो हम संभवतः जल्दी से जल्दी उस जानकारी को हटाने के लिए उचित कदम उठाएंगे। अगर आपको ऐसा लगता है कि हमने किसी अवयस्क की जानकारी का एकत्रण किया है, तो कृपया हमें इस प्राइवेसी नीति के अंत में दिए गए संपर्क जानकारी का उपयोग करके संपर्क करें।
</p>
<h4>आपके विकल्प और अधिकार</h4>
<p className="content_class">
    <h5>8.1. ऑप्ट-आउट:</h5> आपको हमारे द्वारा भेजे गए प्रमोशनल संचार से बाहर निकलने का अधिकार है। इसके लिए, कृपया उन संदेशों में दिए गए अनसब्स्क्राइब निर्देशों का पालन करके हमारे द्वारा भेजे जाने वाले प्रचार या प्रमोशनल संचार से बाहर निकलें। कृपया ध्यान दें कि यदि आप ऑप्ट-आउट कर देते हैं, तो हम आपको गैर-प्रचार संचार जैसे खाता से संबंधित प्रशासनिक संदेश या लेन-देन से संबंधित संदेश के बावजूद भी भेज सकते हैं।</p>

<h5>8.2. अपनी जानकारी तक पहुंच, अद्यतन और सुधार करें:</h5>
<p className="content_class">
    आपके पास हमारे द्वारा रखी गई व्यक्तिगत जानकारी तक पहुंच, अद्यतन और सुधार करने का अधिकार है। यदि आप इस अधिकार का उपयोग करना चाहें, तो कृपया हमारे प्राइवेसी नीति के अंत में दिए गए संपर्क जानकारी का उपयोग करके हमसे संपर्क करें। हम आपके अनुरोध का संभाव्यता के मुताबिक उचित समय सीमा के भीतर जवाब देने के लिए संभावित प्रयास करेंगे।
    </p>
<h4>इस प्राइवेसी नीति में परिवर्तन</h4>
<p className="content_class">
हम समय-समय पर इस प्राइवेसी नीति को अपडेट कर सकते हैं ताकि हमारी अपनी प्रथाओं में या अन्य संचालनिक, कानूनी या नियामक आवश्यकताओं के कारण बदलाव को दर्शाने में सक्षम रहें। हम ऐसे महत्वपूर्ण परिवर्तनों की अधिसूचना द्वारा आपको सूचित करेंगे, जो हम हमारी वेबसाइट पर अद्यतन की गई प्राइवेसी नीति पोस्ट करके और इस पृष्ठ के शीर्ष पर नवीनतम अपडेट की "अंतिम अपडेट" तिथि को अद्यतित करके करेंगे। हम आपको हमारी गोपनीयता अभिवृद्धियों के बारे में सूचित रहने के लिए इस प्राइवेसी नीति की नियमित जांच करने की सलाह देते हैं।</p>

<h4>हमसे संपर्क करें</h4>
<p className="content_class">
यदि आपके पास इस प्राइवेसी नीति के संबंध में कोई प्रश्न, चिंता या अनुरोध है, कृपया हमसे संपर्क करें:
<br/><br/>
पौराणिक मेडिकल एजुकेशन प्राइवेट लिमिटेड
<br/>
पता: 4, अहिल्यापुरी, चिड़ियाघर रोड, रेजीडेंसी क्लब के पास, इंदौर (मध्य प्रदेश)
<br/>
ईमेल: info@pauranikeducation.in
</p>

     </Col>
     </Row>      
     </Container>

            )
}
export default Privacy_hi