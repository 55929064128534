import { atom } from 'recoil';
const language = atom({
    key: 'language', // unique ID (with respect to other atoms/selectors)
    default: 'English', // default value (aka initial value)
  });
  export default language;
// Define an atom
const mylogin = atom({
  key: 'mylogin',
  default: 'false',
});
const myname = atom ({
    key:'myname',
    default:'',
})

export  {mylogin, myname, language};