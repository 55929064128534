import React from "react";
import { Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function LoadingMenus()
{
    return(
        <div style={{padding:'15px',backgroundColor:'rgb(0, 118, 148)' }}>
        <SkeletonTheme baseColor="rgb(0, 118, 148)">
      <div style={{ display: 'flex', gap: '10px'}}>
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} height={30} width={100} style={{marginLeft:'70px'}}/>
        ))}
      </div>
    </SkeletonTheme>
    </div>        
    )
}
export default LoadingMenus