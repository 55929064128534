import React from "react";
import { Container, Col,Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Loading_Form() {
  return (
    <>
<Container className="align-items-center justify-content-center">
<Col sm={8} className="content-box" style={{ padding: "5%" }}>
<SkeletonTheme color="#f0f0f0" highlightColor="#e0e0e0">
<Skeleton count={1} height={50} width={350}/>
<Skeleton style={{marginTop:'40px'}}  count={1} height={30} width={250}/>
<Skeleton style={{marginTop:'10px'}}  count={1} height={30} />
<Skeleton style={{marginTop:'40px'}}  count={1} height={30} width={250}/>
<Skeleton style={{marginTop:'10px'}}  count={1} height={30} />
<Skeleton style={{marginTop:'40px'}}  count={1} height={30} width={250}/>
<Skeleton style={{marginTop:'10px'}}  count={1} height={30} />
<Skeleton style={{marginTop:'40px'}}  count={1} height={30} width={250}/>
<Skeleton style={{marginTop:'10px'}}  count={1} height={30} />
<Skeleton style={{marginTop:'40px'}}  count={1} height={30} width={70} />


</SkeletonTheme>
    </Col>
    
 
    </Container>
    </>
  );
}

export default Loading_Form;
