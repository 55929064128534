import React from "react";
import { Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function LoadingHeader()
{
    return(
        <div className="p-3" style={{ overflow: "auto" }}>
           <div style={{ float: "left" }}> 
            <Skeleton count={1} width={90} borderRadius={90}height={100}></Skeleton>                
                
             </div>
        <div className="m-3" style={{ float: "left", marginLeft: "20px" }}>
          <h1
            style={{
              fontFamily: "Arial",
              fontSize: "24px",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            <Skeleton height={55}></Skeleton>
            
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ fontWeight: "bold" }}>
            <div className="d-sm-block d-xl-none">
        <Skeleton height={35} width={200} />
      </div>

      {/* On extra-large screens */}
      <div className="d-none d-xl-block">
        <Skeleton height={35} width={500} />
      </div>
            </span>
          </div>
        </div>
       </div> 

    )
}
export default LoadingHeader