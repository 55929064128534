import { Container, Row, Col } from "react-bootstrap";

function To_Announce()
{
return (
    <div>
        <Container>
            <Row>
                <Col style={{marginTop:'3%',marginBottom:'40%'}}>
                <h4>To Be Announced...</h4>
                </Col>
            </Row>
        </Container>
    </div>
)
}
export default To_Announce;