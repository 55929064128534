import { Row,Col, Container } from "react-bootstrap"
import Video_Play from "./Video_Play"

function Privacy_en()
{
 return (
  
    <Container className="content-box">
        <Row>

            <Col>
            
                           <h2>Privacy Policy</h2>

<span className="last-updated">Last updated: May 19, 2023</span>

<p className="content_class">
  Pauranik Academy of Medical Education Pvt Ltd ("us", "we", or "our") operates the website www.pauranikeducation.in (the "Website"). We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our Website.
  <br/><br/>
  By accessing or using our Website, you agree to the terms of this Privacy Policy. If you do not agree with the practices described in this policy, please do not use the Website.
</p>

<h4>Information We Collect</h4>
<h5>1.1. Personal Information:</h5>
<p className="content_class">
  We may collect personal information, such as your name, email address, postal address, phone number, and any other information you provide to us voluntarily when you interact with our Website. This information is necessary for us to respond to your inquiries, provide requested services, and improve your experience on our Website.
</p>

<h5>1.2. Non-Personal Information:</h5>
<p className="content_class">
  We may collect non-personal information about you automatically as you navigate through our Website. This may include your IP address, browser type, operating system, device information, and other technical information. We use this information to analyze trends, administer the Website, and gather demographic information about our user base as a whole.
</p>

<h4>How We Use Your Information</h4>
<h5>2.1. Personal Information:</h5>
<p className="content_class">
  We may use your personal information to:
</p>
<ul>
  <li>Respond to your inquiries, provide customer support, and fulfill your requests.</li>
  <li>Send you administrative information, such as updates on policies or changes to our services.</li>
  <li>Personalize and improve your experience on our Website.</li>
  <li>Send you marketing and promotional communications with your consent.</li>
</ul>

<h5>2.2. Non-Personal Information:</h5>
<p className="content_class">
  We may use non-personal information for various purposes, such as:
</p>
<ul>
  <li>Monitoring and analyzing trends, usage, and activities on the Website.</li>
  <li>Improving the functionality and content of the Website.</li>
  <li>Enhancing our services and developing new features.</li>
</ul>

<h4>How We Disclose Your Information</h4>
<h5>3.1. Service Providers:</h5>
<p className="content_class">
  We may engage third-party service providers to perform functions and services on our behalf, such as website hosting, data analysis, and customer support. These service providers may have access to your personal information but are only authorized to use it as necessary to provide the requested services.
</p>

<h5>3.2. Legal Requirements:</h5>
<p className="content_class">
  We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with legal obligations, protect and defend our rights or property, prevent fraud, or protect the personal safety of users.
</p>

<h5>3.3. Business Transfers:</h5>
<p className="content_class">
  In the event of a merger, acquisition, or sale of all or a portion In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you via email and/or prominent notice on our Website of any change in ownership or use of your personal information.</p>

<h4>Cookies and Similar Technologies</h4>
<p className="content_class">
  We may use cookies and similar tracking technologies to enhance your experience on our Website. Cookies are small files that are placed on your device when you visit a website, and they enable us to recognize your browser and remember certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Website.
</p>
<h4>Data Security</h4>
<p className="content_class">
  We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security of your information.
</p>
<h4>Links to Third-Party Websites</h4>
<p className="content_class">
  Our Website may contain links to third-party websites. We do not control or endorse the content or privacy practices of these third-party websites. This Privacy Policy applies only to information collected through our Website. We encourage you to review the privacy policies of any third-party websites you visit to understand how they collect, use, and disclose your information.
</p>
<h4>Children's Privacy</h4>
<p className="content_class">
  Our Website is not intended for individuals under the age of 16. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child under the age of 16, we will take reasonable steps to delete the information as soon as possible. If you believe that we may have collected information from a child under the age of 16, please contact us using the contact information provided at the end of this Privacy Policy.
</p>
<h4>Your Choices and Rights</h4>
<p className="content_class">
  <h5>8.1. Opt-Out:</h5> You have the right to opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications. Please note that even if you opt-out, we may still send you non-promotional communications, such as administrative messages related to your account or transactions.
  <br/><br/>
  <h5>8.2. Access, Update, and Correct Your Information:</h5> You have the right to access, update, and correct your personal information held by us. If you would like to exercise this right, please contact us using the contact information provided at the end of this Privacy Policy. We will make reasonable efforts to respond to your request within a reasonable timeframe.
</p>
<h4>Changes to This Privacy Policy</h4>
<p className="content_class">
  We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on our Website and updating the "Last updated" date at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about our privacy practices.
</p>
<h4>Contact Us</h4>
<p className="content_class">
  If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:
  <br/><br/>
  Pauranik Academy of Medical Education Pvt Ltd
  <br/>
  Address: 4, Ahilyapuri, Zoo Road, Near Residency Club, Indore (M.P.)
<br/>
Email: info@pauranikeducation.in

</p>

    
    </Col>
        </Row>
    </Container>
 )
}
export default Privacy_en