import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import axios from "axios";
import Payment from "../Payment";
function Make_Payment ()
{
    const location = useLocation();
const searchParams = new URLSearchParams(location.search);

const txnId = searchParams.get('txnId');

const [id, setID] = useState('');
const [mobile, setMobile] = useState('');
const [ispayment, setPayment] = useState(false);
const [idError, setIdError] = useState('');
const [order_id, setOrderId] = useState('');
const [token, setToken]  = useState('')
const mysearch = new FormData();
const [data, setData] = useState([]);
mysearch.append('searchKey','');
mysearch.append('txnId','');
useEffect(() => {
  if(txnId || searchParams.get('value'))
  {
    mysearch.set('txnId',searchParams.get('txnId'));
   
        setID(searchParams.get('value'));
        setMobile(searchParams.get('value'));
        mysearch.set('searchKey',searchParams.get('value'));
       fetch_user(mysearch);
    }
  
}, [])

const fetch_user = async (mysearch) => {
    const url = 'https://pauranikeducation.in/server_files/fetch_details.php';

    try {
      const response = await axios.post(url, mysearch, { withCredentials: true });
      // Process the response data
    console.log(response.data);
        if(response.data==0)
        {
          alert('No any User Registered with This Number');
        }
        else {
         setData(response.data);
        }
    }   
     catch (error) {
      console.log(error);
      alert('There is some Error in your form');
    } finally {
    }
  };

  const inipayment = async (mobile) => {
    setPayment(false);
    setToken('');
    setOrderId('');
    const mypay = new FormData();
    mypay.append('make_pay',mobile);
    const url = 'https://arth-dhwani.com/pame/server_files/transaction.php';

    try {
      const response = await axios.post(url, mypay, { withCredentials: true });
       if(response.data=="0")
        {
          alert('No any User Registered with This Number');
        }
        else if(response.data==='1'){
        alert('There is something wrong, please try again later, or contact support!!');
        }
        else
        {
          const responseData = JSON.parse(response.data);
          const resultMsg = responseData.body.resultInfo.resultMsg;
          const resultCode = responseData.body.resultInfo.resultCode;
          const txnToken = responseData.body.txnToken;
          const orderId = responseData.body.order_id;
          if(resultMsg==='Success' && resultCode==='0000'){
            setOrderId(orderId);
            setToken(txnToken);
            setPayment(true);
          }
          else
          {
            alert('There is something wrong, please try again later, or contact support!!')
          }


        }
    }   
     catch (error) {
      console.log(error);
      alert('There is some Error in your form');
    } finally {
    }
  };



  const submitSearchForm = (e)=>
  {
    e.preventDefault();
    let flag = true;
        if(mobile==='')
        {
            setIdError('Please Enter Registered Mobile Number');
            flag = false;
        }
      else if (!(/^\d{10}$/).test(mobile)) {
            setIdError('Mobile number must be a 10-digit number !!');
            flag=false;
          }
          if(flag===true)
          {
            mysearch.set('searchKey',mobile);
            fetch_user(mysearch);
          }

  }


    return (
        <div>
 <Container className="align-items-center justify-content-center">
        <Row>
          <Col sm={8} className="content-box" style={{ padding: "5%" }}>
            <h2 style={{color:'green'}}>Registration Completed !!</h2>
         {id===''?
            <Form onSubmit={submitSearchForm}>
              <Form.Group controlId="name" className="form-group">
                <Form.Label>Enter Registered Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                   setIdError("");
                  }}
                  isInvalid = {idError!==''?true:false}
                />
                <span className="error-text">{idError}</span>
              </Form.Group>
              <Button type="submit">Submit</Button>
             </Form>:''}
             {data && Array.isArray(data)? data.map((item,index) =>
             <div className="table-responsive">
            <Table bordered>
  {item.txn_id !== null && item.txn_id!=='0' && (
    <>
      <tbody>
        <tr>
          <th style={{ padding: '10px' }}>Order ID</th>
          <td>{item.order_id}</td>
        </tr>
        <tr>
          <th style={{ padding: '10px' }}>Transaction ID</th>
          <td>{item.txn_id}</td>
        </tr>
        <tr>
          <th style={{ padding: '10px' }}>Transaction Status</th>
          <th style={{color:item.status==='TXN_SUCCESS'?'green':'red'}}>{item.status==='TXN_SUCCESS'?'Transaction Successfull':'Transaction Failed'}</th>
        </tr>
      </tbody>
    </>
  )}
  <tbody>
    <tr>
      <th style={{ padding: '10px' }}>Name</th>
      <td>{item.name.toUpperCase() + ' ' + item.surname.toUpperCase()}</td> </tr>
      <tr>
      <th>Sex</th>
      <td>{item.sex}</td>
    </tr>
    <tr>
      <th style={{ padding: '10px' }}>Email</th>
      <td>{item.email}</td></tr>
      <tr>
      <th>Mobile</th>
      <td>{item.mobile}</td>
    </tr>
    <tr>
      <th style={{ padding: '10px' }}>City</th>
      <td>{item.city.toUpperCase() + ', ' + item.state.toUpperCase()}</td>
    </tr>
    <tr>
      <th style={{ padding: '10px' }}>Applying For</th>
      <td>
        {item.applying_for + ' in ' + item.enrolled_in.toUpperCase()}
      </td>
    </tr>
    <tr>
      <th style={{ padding: '10px' }}>Fee</th>
      <td>{'₹' + item.amount}</td></tr>
      <tr><th>Status</th>
      <th style={{ color: item.paid === '0' ? 'red' : 'green' }}>
        {item.paid === '0' ? 'Unpaid' : 'Paid'}
      </th>
    </tr>
    {item.txn_id !== null && item.txn_id!=='0' && (
      <>
        <tr>
          <th style={{ padding: '10px' }}>Date-Time:</th>
          <td>{item.date}</td>
        </tr>

      </>
    )}
  </tbody>
</Table>

<p><strong><small>Note: This Registration does not require any Payment. This Course/Internship is free for a limited Period of Time</small></strong></p>
             {/*<div className="text-center m-3">{item.paid==='0'?<Button onClick={()=>inipayment(item.mobile)}>Pay Now</Button>:<h3>This Application form is Successfuly Paid</h3>}</div>*/}
            </div> 
             )
                 
             
             
             :'no it is not'}
             </Col>
             </Row>
             </Container>
                {ispayment?<Payment order_id={order_id} token = {token}/>:''}
           
        </div>
    )
}
export default Make_Payment