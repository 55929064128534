import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div>
    <Container  fluid style={{paddingTop:'15px',paddingBottom:'10px', backgroundColor: 'rgb(0, 118, 148)',color:'white' }}>
      <Row>
      <Col  style={{color:'white', textAlign:'left'}}>
          Developed by <a href="#" style={{ color: 'white' }}>TeamIT@pauranikeducation.in</a>
            </Col>
        <Col  style={{textAlign:'center', color:'white'}}>
        &copy; Pauranikeducation.in
          </Col>
          <Col  style={{textAlign:'right'}}>
        <div><Link to="/privacy-policy" style={{ color: 'white' }}>
            Privacy Policy 
          </Link>  </div>
          <div><Link to="/terms-and-condition" style={{color: 'white' }}>
            Terms & Conditions 
          </Link></div>
          <div><Link to="/refund-policy" style={{ color: 'white' }}>
            Refund/Cancellation Policy
          </Link></div>
        </Col>
     

      </Row>
    </Container>

    </div>
  );
}

export default Footer;
