import { useRecoilState } from "recoil";
import language from "./Atom_File";
function Language_component ()
{
const [lang,setLanguage] = useRecoilState(language);

const handleChangeLanguage = (event) => {
  setLanguage(event.target.value);
};

    return (
        <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
         alignItems: "center",
        }}
      >
        <select value={lang} onChange={handleChangeLanguage}>

          <option value="English">English</option>
        </select>
      
      </div>
    )
}
export default Language_component;