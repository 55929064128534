import React from "react";
import { Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function LoadingPage() {
  return (
    <Container className="content-box">
    <SkeletonTheme color="#f0f0f0" highlightColor="#e0e0e0">
    <Skeleton count={1} height={140}/>
    <div style={{marginTop:'18px'}}>
        <Skeleton height={50} style={{marginTop:'12px'}}/>
        <Skeleton height={50} width={250}/>
        
        <div style={{marginTop:'15px'}}>

            <Skeleton borderRadius={10} count={12}/>

            </div>
            </div>
    </SkeletonTheme>
    </Container>
  );
}

export default LoadingPage;
